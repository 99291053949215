<ng-container [ngSwitch]="assetApprovalStatus" assetApprovalStatusName="assetApprovalStatusName">

  <ng-container *ngSwitchCase="'approved'">
    <span class="fa-stack mr-4">
      <span class="fas fa-fw fa-circle fa-stack-2x green-50-fg"></span>
      <span class="fas fa-fw fa-check fa-stack-1x green-600-fg"></span>
    </span>
    <span>{{'APPROVED' | translate}}</span>
  </ng-container>

  <ng-container *ngSwitchCase="'refused'">
    <span class="fa-stack mr-4">
      <span class="fas fa-fw fa-circle fa-stack-2x warn-50-fg"></span>
      <span class="fas fa-times fa-stack-1x warn-600-fg"></span>
    </span>
    <span>{{'REFUSED' | translate}}</span>
  </ng-container>

  <ng-container *ngSwitchCase="'waiting'">
    <span class="fa-stack mr-4">
      <span class="fas fa-fw fa-circle fa-stack-2x amber-50-fg"></span>
      <span class="far fa-fw fa-clock fa-stack-1x amber-600-fg"></span>
    </span>
    <span>{{'WAITING' | translate}}</span>
  </ng-container>

  <ng-container *ngSwitchCaseDefault>
    <span class="fa-stack mr-4">
      <span class="fas fa-fw fa-circle fa-stack-2x amber-50-fg"></span>
      <span class="far fa-fw fa-clock fa-stack-1x amber-600-fg"></span>
    </span>
    <span>{{'WAITING' | translate}}</span>
  </ng-container>

</ng-container>
