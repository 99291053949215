import { Directive } from '@angular/core';
import {
  AsyncValidator,
  AbstractControl,
  ValidationErrors,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { AuctionService } from 'app/main/services/auction.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appAuctionCodeValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: AuctionCodeValidatorDirective,
      multi: true,
    },
  ],
})
export class AuctionCodeValidatorDirective implements AsyncValidator {
  constructor(private service: AuctionService) {}
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return null;
    }
    return this.service.getWhere({ auction_code: { eq:  control.value }}, { limit: 1, offset: 0 }).pipe(
      map(({ data }) => {
        return data.length ? { auctionCodeExists: true} : null;
      })
    );
  }
}
