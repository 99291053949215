export const locale = {
  lang: 'id',
  data: {
    'CONFIRM': {
      'MESSAGE': 'Anda yakin?',
      'CANCEL' : 'Batal',
      'CONFIRM' : 'Ya!'
  }
  }
};
