import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { AfterViewInit, Component, OnChanges, OnDestroy, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { Router, NavigationEnd } from '@angular/router';
import { HelpersService } from 'app/main/services/helpers.service';
import { BehaviorSubject } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector   : 'footer',
  templateUrl: './footer.component.html',
  styleUrls  : ['./footer.component.scss']
})
export class FooterComponent implements OnChanges, AfterViewInit, OnDestroy
{
  constructor(private breakpoint$: BreakpointObserver, private helpers: HelpersService, private router: Router) {
    this.breakpoint$.observe([Breakpoints.HandsetLandscape, Breakpoints.HandsetPortrait]).subscribe(result => {
      if (result) {
        this.hidePageSize = result.matches;
      }
    });
    this.helpers.pageLength$ =  new BehaviorSubject<number>(0);
    this.helpers.pageLength$.subscribe(length => {
      this.length = length;
    });

  }
  length = 0;
  hidePageSize = false;
  pageSizeOptions = [0, 5, 15, 50, 100, 500, 1000];
  @ViewChild(MatPaginator) public paginator: MatPaginator;

  ngOnChanges(): void{
    this.hidePageSize = this.breakpoint$.isMatched('(max-width: 599px)');
  }
  ngAfterViewInit(): void{
    if (this.paginator) {
      this.paginator._intl.itemsPerPageLabel = 'Baris';
    }
    this.helpers.firstPage$ = new BehaviorSubject(true);
    this.helpers.firstPage$.subscribe(() =>  this.paginator.firstPage());
    this.router.events
    .pipe(filter((event => event instanceof NavigationEnd)))
    .subscribe(() => {
      this.paginator.firstPage();
      this.paginator.pageSize = 5;
      this.helpers.pageChange$.next(this.paginator.page);
    });
  }
  ngOnDestroy(): void{
    this.helpers.pageLength$.unsubscribe();
  }
  onPageChange(page): void{
    this.helpers.pageChange$.next(page);
  }
}
