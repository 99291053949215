import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HelpersService } from '../helpers.service';
import { Majors } from './majors';

@Injectable({
  providedIn: 'root'
})
export class MajorsService extends BaseApiService<Majors> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'employee-major';
  }
}
