import { Component, Input, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as en } from './i18n/en';
import { locale as id } from './i18n/id';

@Component({
  selector: 'app-asset-approval-status-label',
  templateUrl: './asset-approval-status-label.component.html',
  styleUrls: ['./asset-approval-status-label.component.scss']
})
export class AssetApprovalStatusLabelComponent implements OnInit {

  @Input() statusName: string;
  @Input() currentStatus: string;
  colorName: string = 'green';
  status: string;
  firstStatus: string;
  secondStatus: string;

  constructor(
    public trans: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService
  ) {
    this.fuseTranslationLoader.loadTranslations(en, id);
  }

  ngOnInit(): void {
    this.setColorName();
  }

  setColorName() {
    const splitStatus = this.statusName.split('.');
    const splitCurrentStatus = this.currentStatus.split('.');
    this.firstStatus = splitStatus[0];
    this.secondStatus = splitCurrentStatus[0];
    this.status = splitStatus[splitStatus.length - 1];

    // if (this.statusName == this.currentStatus) {
    //   this.colorName = 'blue';
    // } else if (this.statusName != this.currentStatus && this.status == 'refused' && this.firstStatus == this.secondStatus) {
    //   this.colorName = 'warn';
    // }

    // switch (this.status) {
    //   case 'pending':
    //     this.colorName = 'lime';
    //     break;
    //   case 'refused':
    //     this.colorName = 'warn';
    //     break;
    //   case 'discontinue':
    //     this.colorName = 'indigo';
    //     break;
    //   default:
    //     this.colorName = 'green';
    //     break;
    // }
  }

}
