import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from 'app/main/services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class ErrorInterceptorService implements HttpInterceptor {

  constructor(
    private auth: AuthService
    ) { }
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

      // patch: exclude interceptor 401 for /reset-password
      if(['/sites/','/menus/main', '/notifications'].some(v => request.url.includes(v))){
        return next.handle(request);  
      }

      return next.handle(request).pipe(catchError(err => {
        if (err.status === 401) {
          this.auth.logout(true);
        }
        // console.error(err);
        const error = err.error;
        error.message = error.message ? error.message : err.statusText;
        return throwError(error);
      }));
    }
  }
