import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'lodash';

import { FuseConfigService } from '@fuse/services/config.service';
import { FuseSidebarService } from '@fuse/components/sidebar/sidebar.service';

import { navigation } from 'app/navigation/navigation';
import { LangStorageService } from 'app/main/services/lang.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

import { locale as english } from './i18n/en';
import { locale as indonesia } from './i18n/id';
import { AuthService } from 'app/main/services/auth.service';
import { Users } from 'app/main/services/users';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../confirm-dialog/confirm-dialog.component';
import { LogsActivityService } from 'app/main/services/logs-activity.service';
import { LogsActivity } from 'app/main/services/logs-activity';
import { HelpersService } from 'app/main/services/helpers.service';
import { AppNotification, AppNotificationService } from 'app/main/services/notifications';
import { Router } from '@angular/router';
import { Projects } from 'app/main/services/projects';
import { HttpQuery } from 'app/main/services/http-query';
@Component({
    selector: 'toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
    encapsulation: ViewEncapsulation.None
})

export class ToolbarComponent implements OnInit, OnDestroy {
    horizontalNavbar: boolean;
    rightNavbar: boolean;
    hiddenNavbar: boolean;
    languages: any;
    navigation: any;
    selectedLanguage: any;
    userStatusOptions: any[];
    appNotifications: AppNotification[];
    unreadNotifications: number = 0;
    subs: Subscription;
    currentUser: Users;
    // Private
    private _unsubscribeAll: Subject<any>;

    /**
     * Constructor
     *
     * @param {FuseConfigService} _fuseConfigService
     * @param {FuseSidebarService} _fuseSidebarService
     * @param {TranslateService} _translateService
     */
    constructor(
        private _fuseConfigService: FuseConfigService,
        private _fuseSidebarService: FuseSidebarService,
        private _translateService: TranslateService,
        private langStorage: LangStorageService,
        private _fuseTranslationLoaderService: FuseTranslationLoaderService,
        private auth: AuthService,
        private confirmDialog: MatDialog,
        private logs: LogsActivityService,
        private router: Router,
        public helper: HelpersService,
        public srvNotification: AppNotificationService
    ) {
        this.subs = new Subscription();
        // Set the defaults
        this.userStatusOptions = [
            {
                title: 'Online',
                icon: 'icon-checkbox-marked-circle',
                color: '#4CAF50'
            },
            {
                title: 'Away',
                icon: 'icon-clock',
                color: '#FFC107'
            },
            {
                title: 'Do not Disturb',
                icon: 'icon-minus-circle',
                color: '#F44336'
            },
            {
                title: 'Invisible',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#BDBDBD'
            },
            {
                title: 'Offline',
                icon: 'icon-checkbox-blank-circle-outline',
                color: '#616161'
            }
        ];

        this.languages = [
            {
                id: 'en',
                title: 'English',
                flag: 'us'
            },
            {
                id: 'id',
                title: 'Indonesia',
                flag: 'id'
            }
        ];
        this.currentUser = this.auth.getLoggedUser();
        this.navigation = navigation;
        this._fuseTranslationLoaderService.loadTranslations(english, indonesia);
        // Set the private defaults
        this._unsubscribeAll = new Subject();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        // Subscribe to the config changes
        this._fuseConfigService.config
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((settings) => {
                this.horizontalNavbar = settings.layout.navbar.position === 'top';
                this.rightNavbar = settings.layout.navbar.position === 'right';
                this.hiddenNavbar = settings.layout.navbar.hidden === true;
            });

        // Set the selected language from default languages
        this.selectedLanguage = _.find(this.languages, { id: this._translateService.currentLang });
        this.subs = this.langStorage.lang$.subscribe(lang => {
            this.selectedLanguage = _.find(this.languages, { id: lang });
        });
        this.getNotifications();
    }
    logout(): void {
      this.confirmDialog.open(ConfirmDialogComponent).afterClosed().subscribe(isLogout => {
        if (isLogout) {
          const logData: LogsActivity = {
            desc: '{{user}} keluar aplikasi',
            type: 'logout',
            extra: JSON.stringify(this.currentUser),
            uaccount_id: this.currentUser.id
          };
          this.logs.insert(logData).pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
            this.auth.invalidateToken().subscribe({
              next: () => this.auth.logout(true)
            })
          });
        }
      });
    }
    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next();
        this._unsubscribeAll.complete();
        this.subs.unsubscribe();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Toggle sidebar open
     *
     * @param key
     */
    toggleSidebarOpen(key): void {
        this._fuseSidebarService.getSidebar(key).toggleOpen();
    }

    /**
     * Search
     *
     * @param value
     */
    search(value): void {
        // Do your search here...
        // console.log(value);
        this.helper.search$.next(value);
    }

    /**
     * Set the language
     *
     * @param lang
     */
    setLanguage(lang): void {
        // Set the selected language for the toolbar
        this.selectedLanguage = lang;

        // Use the selected language for translations
        this._translateService.use(lang.id);
        this.langStorage.setLangStorage(lang.id);

    }

    showAllNotifications(): void {
      this.router.navigate(['/notifications']);
    }

    getNotifications(): void {
      const query: HttpQuery = {
        sort: '-id',
        limit: 10,
        offset: 0
      }
      this.srvNotification.getAll(query).subscribe({
        next: ({data}) => {
          this.appNotifications = data;
          this.countUnreadNotifications();
        }
      });
    }

    parseDesc(desc: string, extra: AppNotification): string{
      let by_user: string = null;
      let for_user: string = null;
      let project: Projects = null;
      let pic: string = null;

      if (extra.prlist_id) {
        project = extra.project;

        if (extra.project.pic_id) {
          pic = extra.project.pic.fullname;
          if (parseInt(extra.project.pic_id) === this.currentUser.id) {
            pic = 'Anda'
          }
        }
      }

      if (extra.by_user_id) {
        by_user = extra.by_user.fullname;
        if (extra.by_user_id === this.currentUser.id) {
          by_user = 'Anda'
        }
      }

      if (extra.for_user_id) {
        for_user = extra.for_user.fullname;
        if (extra.for_user_id === this.currentUser.id) {
          for_user = 'Anda'
        }
      }

      return desc
        .replace('{{by_user}}', `<strong>${by_user}</strong>`)
        .replace('{{for_user}}', `<strong>${for_user}</strong>`)
        .replace('{{pic}}', `<strong>${pic}</strong>`)
        .replace('{{project}}', `<strong><a class="blue-600-fg" href="/projects/${project.id}/detail">${project.name}</a></strong>`);
    }

    openNotification(data: AppNotification): void {
      if (!data.is_read) {
        this.srvNotification.markAsRead(data.id).subscribe({
          next: () => this.getNotifications()
        });
      }

      switch (data.event_type) {
        case 1:
          this.router.navigateByUrl(`/projects/${data.prlist_id}/detail`);
          break;

        case 3:
        case 4:
          this.router.navigateByUrl(`/projects/${data.prlist_id}/rab`);
          break;

        default:
          break;
      }
    }

    countUnreadNotifications(): void {
      if (!this.appNotifications) {
        return;
      }

      const notif = [...this.appNotifications];
      notif.filter(value => value.is_read === false);
      this.unreadNotifications = notif.filter(value => value.is_read === false).length;
    }
}
