import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';

import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { ActionsColumnModule } from 'app/main/layout/tables/actions-column/actions-column.module';
import { BreadcrumbModule } from 'app/main/components/breadcrumb/breadcrumb.module';
import { MatRadioModule } from '@angular/material/radio';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSelectModule } from '@angular/material/select';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ConfirmDialogModule } from 'app/layout/components/confirm-dialog/confirm-dialog.module';
import { ToolbarButtonModule } from 'app/main/components/toolbar-button/toolbar-button.module';
import { FuseSidebarModule } from './components';
import { NgxCurrencyModule } from 'ngx-currency';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { MatTabsModule } from '@angular/material/tabs';
import { MatCardModule } from '@angular/material/card';

import { NgxUploadModule } from '@wkoza/ngx-upload';
import { HttpClientModule } from '@angular/common/http';
import { DateLabelModule } from 'app/main/components/date-label/date-label.module';
import { NodataLabelModule } from 'app/main/components/nodata-label/nodata-label.module';
import { CuanLabelModule } from 'app/main/components/cuan-label/cuan-label.module';
import { AssetApprovalStatusLabelModule } from 'app/main/components/asset-approval-status-label/asset-approval-status-label.module';
import { AssetApprovalStatusModule } from 'app/main/components/asset-approval-status/asset-approval-status.module';
import { MatTooltipModule } from '@angular/material/tooltip';
import { NoteDialogModule } from 'app/layout/components/note-dialog/note-dialog.module';
import { ShowNoteDialogModule } from 'app/layout/components/show-note-dialog/show-note-dialog.module';
@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,

    FuseDirectivesModule,
    FusePipesModule,
    HttpClientModule,
    NgxUploadModule.forRoot(),
  ],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,

    FlexLayoutModule,
    ActionsColumnModule,

    FuseDirectivesModule,
    FusePipesModule,
    MatDialogModule,
    MatTooltipModule,
    MatTableModule,
    MatCheckboxModule,
    MatIconModule,
    MatMenuModule,
    MatListModule,
    MatSortModule,
    MatButtonModule,
    MatInputModule,
    MatSlideToggleModule,
    BreadcrumbModule,
    MatRadioModule,
    MatDatepickerModule,
    MatSelectModule,
    MatAutocompleteModule,
    MatTabsModule,
    NgxChartsModule,
    ConfirmDialogModule,
    ToolbarButtonModule,
    FuseSidebarModule,
    NgxCurrencyModule,
    CarouselModule,
    NgCircleProgressModule,
    MatCardModule,
    DateLabelModule,
    NodataLabelModule,
    CuanLabelModule,
    AssetApprovalStatusLabelModule,
    AssetApprovalStatusModule,
    NoteDialogModule,
    ShowNoteDialogModule
  ]
})
export class FuseSharedModule {
}
