import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LangStorageService {

  constructor() {
    this.lang$ = new BehaviorSubject<string>('id');
    this.checkLang();
    setTimeout(() => {
      this.lang$.next(this.getLangStorage());
    });
  }
  lang$: BehaviorSubject<string>;
  setLangStorage(lang = 'id'): void {
    localStorage.setItem('lang', lang);
  }
  isLangExist(): boolean {
    return localStorage.getItem('lang') ? true : false;
  }
  checkLang(): void{
    if (!this.isLangExist()) {
      this.setLangStorage();
    }
  }
  getLangStorage(): string {
    this.checkLang();
    return localStorage.getItem('lang');
  }
}
