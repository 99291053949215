import { NgModule } from '@angular/core';
import { BrowserModule, Meta, Title } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { TranslateModule } from '@ngx-translate/core';

import { FuseModule } from '@fuse/fuse.module';
import { FuseSharedModule } from '@fuse/shared.module';
import { FuseProgressBarModule, FuseSidebarModule } from '@fuse/components';

import { fuseConfig } from 'app/fuse-config';

import { AppComponent } from 'app/app.component';
import { PagesModule } from './main/pages/pages.module';
import { HttpInterceptors } from './interceptors';
import { ShortenPipe, StripTagsPipe, TrimPipe } from 'ngx-pipes';
import { JwtModule } from '@auth0/angular-jwt';
import { LayoutModule } from './layout/layout.module';
export function tokenGetter(): string {
  return localStorage.getItem('pmo-token');
}
export const allowedDomains = ['profileimage.studio', 'ptsiprojects.com', 'localhost', 'localhost:4200', 'somat.web.id', 'manpro.somat.web.id'];
import { CurrencyMaskInputMode, NgxCurrencyModule } from 'ngx-currency';
export const customCurrencyMaskConfig = {
  align: 'right',
  allowNegative: true,
  allowZero: true,
  decimal: ',',
  precision: 2,
  prefix: 'Rp ',
  suffix: '',
  thousands: '.',
  nullable: true,
  min: null,
  max: null,
  inputMode: CurrencyMaskInputMode.NATURAL
};
import localeId from '@angular/common/locales/id';
import localeIdExtra from '@angular/common/locales/extra/id';
import { registerLocaleData } from '@angular/common';
import { OldnewPasswordValidatorDirective } from './directives/oldnew-password-validator.directive';
import { AuctionCodeValidatorDirective } from './directives/auction-code-validator.directive';
import { MarriedStatusValidatorDirective } from './directives/married-status-validator.directive';
registerLocaleData(localeId,  localeIdExtra);
@NgModule({
  declarations: [
    AppComponent,
    OldnewPasswordValidatorDirective,
    AuctionCodeValidatorDirective,
    MarriedStatusValidatorDirective,
  ],
  imports     : [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    PagesModule,
    TranslateModule.forRoot(),

    // Material moment date module
    MatMomentDateModule,

    // Material
    MatButtonModule,
    MatIconModule,
    MatSnackBarModule,

    // Fuse modules
    FuseModule.forRoot(fuseConfig),
    FuseProgressBarModule,
    FuseSharedModule,
    FuseSidebarModule,
    // FuseThemeOptionsModule,
    NgxCurrencyModule.forRoot(customCurrencyMaskConfig),
    // App modules
    LayoutModule,
    JwtModule.forRoot({
      config: {
        tokenGetter,
        allowedDomains,
      },
    }),
  ],
  bootstrap   : [
    AppComponent
  ],
  providers: [
    HttpInterceptors,
    Title,
    Meta,
    ShortenPipe,
    StripTagsPipe,
    TrimPipe,
  ]
})
export class AppModule
{
}
