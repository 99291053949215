import { Component, Inject, ViewEncapsulation } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { locale as english } from './i18n/en';
import { locale as indonesia } from './i18n/id';
@Component({
  selector: 'app-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
    constructor(
      public dialogRef: MatDialogRef<ConfirmDialogComponent>, 
      private _fuseTranslationLoader: FuseTranslationLoaderService,
      @Inject(MAT_DIALOG_DATA) public data: any,
    ) 
    {
     this._fuseTranslationLoader.loadTranslations(english, indonesia);
    }
}
