import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CuanLabelComponent } from './cuan-label.component';



@NgModule({
  declarations: [CuanLabelComponent],
  imports: [
    CommonModule
  ],
  exports: [
    CuanLabelComponent
  ]
})
export class CuanLabelModule { }
