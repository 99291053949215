import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AssetApprovalStatusComponent } from './asset-approval-status.component';
import { TranslateModule } from '@ngx-translate/core';



@NgModule({
  declarations: [AssetApprovalStatusComponent],
  imports: [
    CommonModule,
    TranslateModule
  ],
  exports: [
    AssetApprovalStatusComponent
  ]
})
export class AssetApprovalStatusModule { }
