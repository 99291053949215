import { Directive } from '@angular/core';
import {
  AsyncValidator,
  AbstractControl,
  ValidationErrors,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { MarriedStatusService } from 'app/main/services/master-employee';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Directive({
  selector: '[appMarriedStatusValidator]',
  providers: [
    {
      provide: NG_ASYNC_VALIDATORS,
      useExisting: MarriedStatusValidatorDirective,
      multi: true,
    },
  ],
})
export class MarriedStatusValidatorDirective {

  constructor(private service: MarriedStatusService) {}

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value) {
      return null;
    }
    return this.service.getWhere({ code: { eq:  control.value }}, { limit: 1, offset: 0 }).pipe(
      map(({ data }) => {
        return data.length ? { marriedStatusCodeExists: true } : null;
      })
    );
  }

}
