<span class="text-boxed m-0"
      [ngClass]="{'blue-50'   : colorName == 'blue',
                  'warn-50'   : colorName == 'warn',
                  'lime-50'   : colorName == 'lime',
                  'pink-50'   : colorName == 'pink',
                  'indigo-50' : colorName == 'indigo'}">

  <span class="fas fa-fw fa-calendar-alt mr-4"
        [ngClass]="{'blue-fg'       : colorName == 'blue',
                    'warn-fg'       : colorName == 'warn',
                    'lime-700-fg'   : colorName == 'lime',
                    'pink-700-fg'   : colorName == 'pink',
                    'indigo-700-fg' : colorName == 'indigo'}">
  </span>
  <span *ngIf="isParsed">{{data | date:'dd-MM-yyyy':null:trans.currentLang}}</span>
  <span *ngIf="!isParsed">{{data}}</span>

</span>
