import { FuseNavigation } from '@fuse/types';

export const navigation: FuseNavigation[] = [
  {
    id: 'project-group',
    title: 'Projects',
    translate: 'NAV.PROJECT.GROUP',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: 'Dashboard',
        translate: 'NAV.PROJECT.DASHBOARD',
        type: 'item',
        icon: 'pmo_dashboard',
        svgIcon: true,
        url: '/dashboard',
      },
      {
        id: 'project-progress',
        title: 'Job on Progress',
        translate: 'NAV.PROJECT.ONPROGRESS',
        type: 'item',
        icon: 'pmo_projects',
        svgIcon: true,
        url: '/jobs',
        badge: {
          title: '0',
          bg: '#F44336',
          fg: '#FFFFFF'
        }
      },
      {
        id: 'projects',
        title: 'Project List',
        translate: 'NAV.PROJECT.TITLE',
        type: 'collapsable',
        icon: 'pmo_list_project',
        svgIcon: true,
        children: [
          {
            id: 'project-all',
            title: 'All Project',
            translate: 'NAV.PROJECT.ALL',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/projects/all',
            badge: {
              title: '0',
              bg: '#1a237e',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'project-planned',
            title: 'Planned',
            translate: 'NAV.PROJECT.PLANNED',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/projects/planned',
            badge: {
              title: '0',
              bg: '#673ab7',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'project-onprogress',
            title: 'Progress',
            translate: 'NAV.PROJECT.PROGRESS',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/projects/progress',
            badge: {
              title: '0',
              bg: '#ffeb3b',
              fg: '#000'
            }
          },
          {
            id: 'project-done',
            title: 'Progress',
            translate: 'NAV.PROJECT.DONE',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/projects/done',
            badge: {
              title: '0',
              bg: '#1e88e5',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'project-closed',
            title: 'Progress',
            translate: 'NAV.PROJECT.CLOSED',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/projects/closed',
            badge: {
              title: '0',
              bg: '#43a047',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'project-canceled',
            title: 'Progress',
            translate: 'NAV.PROJECT.CANCELED',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/projects/canceled',
            badge: {
              title: '0',
              bg: '#e53935',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'project-onhold',
            title: 'Progress',
            translate: 'NAV.PROJECT.ONHOLD',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/projects/onhold',
            badge: {
              title: '0',
              bg: '#757575',
              fg: '#FFFFFF'
            }
          },
        ]
      },
      {
        id: 'calendar',
        title: 'Calendar',
        translate: 'NAV.PROJECT.CALENDAR',
        type: 'item',
        icon: 'pmo_calendar',
        svgIcon: true,
        url: '/calendar',
      },
      {
        id: 'auction-group',
        title: 'Auction Management',
        translate: 'NAV.AUCTION.GROUP',
        type: 'collapsable',
        children: [
          {
            id: 'auction-monitor',
            title: 'Auction Monitor',
            translate: 'NAV.AUCTION.MONITOR',
            type: 'item',
            url: '/auctions-monitor'
          },
          {
            id: 'auction-manage',
            title: 'Kelola Lelang',
            translate: 'NAV.AUCTION.AUCTION',
            type: 'item',
            url: '/auctions',
          },
          {
            id: 'partner',
            title: 'Partner',
            translate: 'NAV.MASTER.PARTNER',
            type: 'item',
            url: '/partners',
          }
        ]
      }
    ]
  },
  {
    id: 'monetary-group',
    title: 'Monetary',
    translate: 'NAV.MONETARY.GROUP',
    type: 'group',
    children: [
      {
        id: 'earnings',
        title: 'Yearly Earnings',
        translate: 'NAV.MONETARY.EARNINGS',
        type: 'item',
        icon: 'pmo_income',
        svgIcon: true,
        url: '/earnings',
      },
      {
        id: 'income',
        title: 'Receivable Earnings',
        translate: 'NAV.MONETARY.CREDIT',
        type: 'item',
        icon: 'pmo_receivable',
        svgIcon: true,
        url: '/credits',
      },
      {
        id: 'subscriptions',
        title: 'Subscription',
        translate: 'NAV.MONETARY.SUBSCRIPTION',
        type: 'item',
        icon: 'pmo_subscribe',
        svgIcon: true,
        url: '/subscriptions',
      },
      {
        id: 'invoice',
        title: 'Invoice List',
        translate: 'NAV.MONETARY.INVOICE.LIST',
        type: 'collapsable',
        icon: 'pmo_invoice',
        svgIcon: true,
        children: [
          {
            id: 'invoice-saved',
            title: 'Invoice',
            translate: 'NAV.MONETARY.INVOICE.SAVED',
            type: 'item',
            url: '/invoices',
          },
          {
            id: 'invoice-draft',
            title: 'Draft',
            translate: 'NAV.MONETARY.INVOICE.DRAFT',
            type: 'item',
            url: '/invoices-draft',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          }
        ]
      },
      {
        id: 'payment',
        title: 'Payment List',
        translate: 'NAV.MONETARY.PAYMENT',
        type: 'item',
        icon: 'pmo_payments',
        svgIcon: true,
        url: '/payments',
      },
    ]
  },
  {
    id: 'assets-group',
    title: 'Logistic Management',
    translate: 'NAV.ASSETS.GROUP',
    type: 'group',
    children: [
      {
        id: 'assets-dashboard',
        title: 'Assets Dashboard',
        translate: 'NAV.ASSETS.DASHBOARD',
        type: 'item',
        icon: 'pmo_assets_dashboard',
        svgIcon: false,
        url: '/assets/dashboard',
      },
      {
        id: 'assets-approval',
        title: 'Assets Approval',
        translate: 'NAV.ASSETS.APPROVAL',
        type: 'collapsable',
        icon: 'pmo_assets_approval',
        svgIcon: true,
        children: [
          {
            id: 'approval-add',
            title: 'Add Asset',
            translate: 'NAV.ASSETS.APPROVAL_ADD',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/assets/approval/add',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'approval-rental',
            title: 'Rent Asset',
            translate: 'NAV.ASSETS.APPROVAL_RENT',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/assets/approval/rent',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'approval-return',
            title: 'Return Asset',
            translate: 'NAV.ASSETS.APPROVAL_RETURN',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/assets/approval/return',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'approval-discontinue',
            title: 'Add Asset',
            translate: 'NAV.ASSETS.APPROVAL_DISCONTINUE',
            type: 'item',
            // icon     : '',
            // svgIcon  : true,
            url: '/assets/approval/discontinue',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
        ]
      },
      {
        id: 'assets-master',
        title: 'Assets List',
        translate: 'NAV.ASSETS.MASTER',
        type: 'item',
        icon: 'pmo_assets_list',
        svgIcon: false,
        url: '/assets/master',
      },
      {
        id: 'assets-rental',
        title: 'Assets Rental',
        translate: 'NAV.ASSETS.RENTAL',
        type: 'item',
        icon: 'pmo_assets_rental',
        svgIcon: false,
        url: '/assets/rental',
      },
      {
        id: 'assets-return',
        title: 'Assets Return',
        translate: 'NAV.ASSETS.RETURN',
        type: 'item',
        icon: 'pmo_assets_return',
        svgIcon: false,
        url: '/assets/return',
      },
      {
        id: 'assets-discontinue',
        title: 'Assets Discontinue',
        translate: 'NAV.ASSETS.DISCONTINUE',
        type: 'item',
        icon: 'pmo_assets_discontinue',
        svgIcon: false,
        url: '/assets/discontinue',
      },
      {
        id: 'assets-maintenance',
        title: 'Assets Maintenance',
        translate: 'NAV.ASSETS.MAINTENANCE',
        type: 'item',
        icon: 'pmo_assets_maintenance',
        svgIcon: false,
        url: '/assets/maintenance',
      },
      {
        id: 'assets-category',
        title: 'Assets Category',
        translate: 'NAV.ASSETS.CATEGORY',
        type: 'item',
        icon: 'pmo_assets_category',
        svgIcon: false,
        url: '/assets/category',
      },
      {
        id: 'assets-condition',
        title: 'Assets Condition',
        translate: 'NAV.ASSETS.CONDITION',
        type: 'item',
        icon: 'pmo_assets_condition',
        svgIcon: false,
        url: '/assets/condition',
      },
    ]
  },
  {
    id: 'master-group',
    title: 'Master Data',
    translate: 'NAV.MASTER.GROUP',
    type: 'group',
    children: [
      {
        id: 'customer',
        title: 'Customer',
        translate: 'NAV.MASTER.CUSTOMER',
        type: 'item',
        icon: 'pmo_customers',
        svgIcon: true,
        url: '/customers',
      },
      {
        id: 'vendor',
        title: 'Vendor',
        translate: 'NAV.MASTER.VENDOR',
        type: 'item',
        icon: 'pmo_vendor',
        svgIcon: true,
        url: '/vendors',
      },
      {
        id: 'bank-account',
        title: 'Bank Account',
        translate: 'NAV.MASTER.BANK',
        type: 'item',
        icon: 'pmo_bank_account',
        svgIcon: false,
        url: '/bank-accounts',
      },
      {
        id: 'regions',
        title: 'Regions',
        translate: 'NAV.MASTER.REGIONS.TITLE',
        type: 'collapsable',
        icon: 'pmo_regions',
        svgIcon: false,
        children: [
          {
            id: 'regions-province',
            title: 'Province',
            translate: 'NAV.MASTER.REGIONS.PROVINCE',
            type: 'item',
            icon: '',
            svgIcon: false,
            url: '/regions/provinces',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'regions-city',
            title: 'Planned',
            translate: 'NAV.MASTER.REGIONS.CITY',
            type: 'item',
            icon: '',
            svgIcon: false,
            url: '/regions/cities',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'regions-district',
            title: 'Planned',
            translate: 'NAV.MASTER.REGIONS.DISTRICT',
            type: 'item',
            icon: '',
            svgIcon: false,
            url: '/regions/districts',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'regions-subdistrict',
            title: 'Planned',
            translate: 'NAV.MASTER.REGIONS.SUBDISTRICT',
            type: 'item',
            icon: '',
            svgIcon: false,
            url: '/regions/sub-districts',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
        ]
      },
      {
        id: 'jopposition',
        title: 'Job Position',
        translate: 'NAV.MASTER.JOBPOSITION',
        type: 'item',
        icon: 'pmo_job_position',
        svgIcon: false,
        url: '/job-position',
      },
      {
        id: 'users',
        title: 'Users',
        translate: 'NAV.MASTER.USERS.TITLE',
        type: 'collapsable',
        icon: 'pmo_users',
        svgIcon: false,
        children: [
          {
            id: 'users-all',
            title: 'All Users',
            translate: 'NAV.MASTER.USERS.ALL',
            type: 'item',
            // icon     : '',
            // svgIcon  : false,
            url: '/users',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'users-project',
            title: 'Project',
            translate: 'NAV.MASTER.USERS.PROJECT',
            type: 'item',
            // icon     : '',
            // svgIcon  : false,
            url: '/users/project',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'users-monetary',
            title: 'Monetary',
            translate: 'NAV.MASTER.USERS.MONETARY',
            type: 'item',
            // icon     : '',
            // svgIcon  : false,
            url: '/users/monetary',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
          {
            id: 'users-logistic',
            title: 'Logistic',
            translate: 'NAV.MASTER.USERS.LOGISTIC',
            type: 'item',
            // icon     : '',
            // svgIcon  : false,
            url: '/users/logistic',
            badge: {
              title: '0',
              bg: '#F44336',
              fg: '#FFFFFF'
            }
          },
        ]
      },

      {
        id: 'access',
        title: 'User Access',
        translate: 'NAV.MASTER.ACCESS',
        type: 'item',
        icon: 'pmo_access_role',
        svgIcon: false,
        url: '/access',
      },
      {
        id: 'roles',
        title: 'Roles',
        translate: 'NAV.MASTER.ROLES',
        type: 'item',
        icon: 'pmo_user_role',
        svgIcon: false,
        url: '/roles',
      },
      {
        id: 'privilege',
        title: 'Role Privilege',
        translate: 'NAV.MASTER.PRIVILEGE',
        type: 'item',
        icon: 'pmo_role_privilege',
        svgIcon: false,
        url: '/privileges',
      },
      {
        id: 'settings',
        title: 'Settings',
        translate: 'NAV.MASTER.SETTING.TITLE',
        type: 'collapsable',
        icon: 'pmo_menu_setting',
        svgIcon: true,
        children: [
          {
            id: 'company-profile',
            title: 'Company Profile',
            translate: 'NAV.MASTER.SETTING.COMPANY',
            type: 'item',
            url: '/company-profile',
          },
          {
            id: 'site-setting',
            title: 'Site Settings',
            translate: 'NAV.MASTER.SETTING.SITE',
            type: 'item',
            url: '/settings',
          },
          {
            id: 'menu-setting',
            title: 'Menu Settings',
            translate: 'NAV.MASTER.SETTING.MENU',
            type: 'item',
            url: '/menus',
          },
          {
            id: 'backup',
            title: 'Backup',
            translate: 'NAV.MASTER.SETTING.BACKUP',
            type: 'item',
            url: '/backup',
          }
        ]
      },

      {
        id: 'logs',
        title: 'Logs',
        translate: 'NAV.MASTER.LOGS.TITLE',
        type: 'collapsable',
        icon: 'pmo_log',
        svgIcon: false,
        children: [
          {
            id: 'logs-activity',
            title: 'Activity',
            translate: 'NAV.MASTER.LOGS.ACTIVITY',
            type: 'item',
            icon: '',
            svgIcon: false,
            url: '/logs/activity',
          },
          {
            id: 'logs-system',
            title: 'System',
            translate: 'NAV.MASTER.LOGS.SYSTEM',
            type: 'item',
            icon: '',
            svgIcon: false,
            url: '/logs/system',
          },
        ]
      },

    ]
  },

];
