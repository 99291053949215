import { Component, Input, OnInit } from '@angular/core';
export interface Breadcrumbs  {
  title: string;
  url: string;
}
@Component({
  selector: 'app-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss']
})
export class BreadcrumbComponent implements OnInit {

  constructor() { }
  @Input() data: Breadcrumbs[];
  ngOnInit(): void {
  }

}
