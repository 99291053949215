<button type="button" mat-icon-button [matMenuTriggerFor]="toolbarMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #toolbarMenu="matMenu" xPosition="after">
  <button type="button" mat-menu-item (click)="openFilter()">
    <span class="fa-stack mr-4">
      <span class="fas fa-fw fa-circle fa-stack-2x green-50-fg"></span>
      <span class="fas fa-fw fa-filter fa-stack-1x green-600-fg"></span>
    </span>
    <span>{{'TOOL.FILTER' | translate}}</span>
  </button>
  <ng-container *ngIf="!disableDelete">
    <button type="button" mat-menu-item (click)="removeSelected()" [disabled]="selected==0">
      <span class="fa-stack mr-4">
        <span class="fas fa-fw fa-circle fa-stack-2x warn-50-fg"></span>
        <span class="far fa-fw fa-trash-alt fa-stack-1x warn-600-fg"></span>
      </span>
      <span>{{'TOOL.REMOVE.SELECTED' | translate}} ({{selected}})</span>
    </button>
    <button type="button" mat-menu-item (click)="removeAll()">
      <span class="fa-stack mr-4">
        <span class="fas fa-fw fa-circle fa-stack-2x warn-50-fg"></span>
        <span class="fas fa-fw fa-trash-alt fa-stack-1x warn-600-fg"></span>
      </span>
      <span>{{'TOOL.REMOVE.ALL' | translate}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="maintenanceReturn">
    <button type="button" mat-menu-item (click)="returnSelected()" [disabled]="selected==0">
      <span class="fa-stack mr-4">
        <span class="fas fa-fw fa-circle fa-stack-2x cyan-50-fg"></span>
        <span class="fas fa-fw fa-pencil-alt fa-stack-1x cyan-600-fg"></span>
      </span>
      <span>{{'TOOL.RETURN.SELECTED' | translate}} ({{selected}})</span>
    </button>
  </ng-container>
</mat-menu>
