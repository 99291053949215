import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NoteDialogComponent } from './note-dialog.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { FlexLayoutModule } from '@angular/flex-layout';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';

@NgModule({
  declarations: [NoteDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule,
    FormsModule,
    MatInputModule,
    MatFormFieldModule,
  ],
  exports: [
    NoteDialogComponent
  ],
  entryComponents: [NoteDialogComponent]
})
export class NoteDialogModule { }
