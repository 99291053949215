import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { Observable } from 'rxjs';
import { HelpersService } from './helpers.service';
import { HttpQuery } from './http-query';


export interface BaseAPIArray<T> {
  length: number;
  data: T[];
  message: string;
  source?: string;
}
export interface BaseAPIObject<T> {
  data: T;
  message: string;
}
@Injectable({
  providedIn: 'root'
})
export class BaseApiService<T> implements Resolve<T>{
  constructor(public http: HttpClient, public helper: HelpersService) {}
  baseUrl = '/api';
  endpoint: string;

  resolve(): Observable<any> | Promise<any> | any {
    return this.getAll()
  }
  url(): string {
    return [this.baseUrl, this.endpoint].join('/');
  }
  url_id(id): string {
    return [this.baseUrl, this.endpoint, id].join('/');
  }
  url_rel(id, relation): string {
    return [this.baseUrl, this.endpoint, id, relation].join('/');
  }
  url_custom(c): string {
    return [this.baseUrl, this.endpoint, c].join('/');
  }
  url_array(c: string[]): string {
    const baseArray = [this.baseUrl, this.endpoint];
    c.map((a) => baseArray.push(a));
    return baseArray.join('/');
  }
  getAll(query?: HttpQuery): Observable<BaseAPIArray<T>> {
    const url = this.url();
    const params = query ? this.helper.generateParams(query) : {};
    return this.http.get<BaseAPIArray<T>>(url, { params });
  }
  getWhere(where, query?: HttpQuery): Observable<BaseAPIArray<T>> {
    const url = this.url_custom('filter');
    const params = query ? this.helper.generateParams(query) : {};
    return this.http.post<BaseAPIArray<T>>(url, where, { params });
  }
  search(query: HttpQuery, where): Observable<BaseAPIArray<T>> {
    const url = this.url_custom('search');
    const params = this.helper.generateParams(query);
    return this.http.post<BaseAPIArray<T>>(url, where, { params });
  }
  getById(id: string, query: HttpQuery = {}): Observable<BaseAPIObject<T>> {
    const url = this.url_id(id);
    const params = query ? this.helper.generateParams(query) : null;
    return this.http.get<BaseAPIObject<T>>(url, { params });
  }

  insert(data: any): Observable<BaseAPIObject<T>> {
    const url = this.url();
    return this.http.post<BaseAPIObject<T>>(url, data);
  }

  updateById(id: string, data: any): Observable<BaseAPIObject<T>> {
    const url = this.url_id(id);
    return this.http.put<BaseAPIObject<T>>(url, data);
  }

  removeById(id: string): Observable<BaseAPIObject<T>> {
    const url = this.url_id(id);
    return this.http.delete<BaseAPIObject<T>>(url);
  }

  removeAll(): Observable<BaseAPIObject<T>> {
    const url = this.url();
    return this.http.delete<BaseAPIObject<T>>(url);
  }

  setServiceCharge(data: any): Observable<BaseAPIObject<T>> {
    const url = this.url_custom('set-service-charge');
    return this.http.post<BaseAPIObject<T>>(url, data);
  }
}
