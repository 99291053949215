import { HttpParams } from '@angular/common/http';
import { Injectable, isDevMode } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { ShortenPipe, StripTagsPipe, TrimPipe } from 'ngx-pipes';
import { BehaviorSubject } from 'rxjs';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { HttpQuery } from './http-query';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HelpersService {

  constructor(
    private sb: MatSnackBar,
    private sanitizer: DomSanitizer,
    private shorten: ShortenPipe,
    private strip: StripTagsPipe,
    private trim: TrimPipe,
    private trans: TranslateService
  ) {
  }

  search$ = new BehaviorSubject('');
  hidePagination$ = new BehaviorSubject(false);
  pageChange$ = new BehaviorSubject<any>({});
  pageLength$: BehaviorSubject<number>;
  firstPage$: BehaviorSubject<boolean>;
  chartLogColorScheme = {
    domain: ['#5AA454', '#E44D25', '#CFC0BB', '#7aa3e5', '#a8385d', '#aae3f5']
  };
  filter$ = new BehaviorSubject<string>('');
  filterResult$ = new BehaviorSubject<any>({});
  isLoading$ = new BehaviorSubject<boolean>(false);
  menuRefresh$ = new BehaviorSubject<boolean>(false);
  settings$ = new BehaviorSubject<boolean>(false);
  angularEditorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: false,
    height: '200',
    minHeight: '86',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    translate: 'yes',
    enableToolbar: true,
    showToolbar: true,
    defaultParagraphSeparator: '',
    defaultFontName: '',
    defaultFontSize: '',
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'undo',
        'redo',
        'heading',
        'fontName',
        'justifyLeft',
        'strikeThrough',
        'subscript',
        'superscript',
        'justifyCenter',
        'justifyRight',
        'justifyFull',
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'link',
        'unlink',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'removeFormat',
      ]
    ]
  };

  percentagePattern = /^(\d{0,2}(\.\d+)?|100(\.0+)?)$/;
  static toExportFileName(filename: string): string {
    return `${filename} [${moment().format('DD-MM-YYYY hh.mm.ss')}].xlsx`;
  }

  padleft(c): string {
    return `${c * 16}px`;
  }
  sbError(message: string, action = null, autoClose: boolean | MatSnackBarConfig = true): any {
    return this.sb.open(
      message,
      action ? action : this.closeActionText(),
      autoClose ? { duration: 5000, panelClass: ['pmo-snackbar', 'pmo-snackbar--error'] } : {}
    );
  }
  sbSuccess(message: string, action = null): any {
    return this.sb.open(message, action ? action : this.closeActionText(), { duration: 5000, panelClass: ['pmo-snackbar', 'pmo-snackbar--success'] });
  }
  closeActionText(): string {
    return this.trans.currentLang === 'en' ? 'Close' : 'Tutup';
  }
  parseUrl(url): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(this.setHttp(url));
  }
  setHttp(link): string {
    if (link && link.search(/^http[s]?\:\/\//) === -1) {
      link = 'http://' + link;
    }
    return link;
  }
  shortIt(text: string): string {
    if (!text) { return '-'; }
    return this.trim.transform(this.shorten.transform(this.strip.transform(text), 36, '...'));
  }
  generateParams({ limit, offset, sort, search, include, where, status, mt }: HttpQuery): HttpParams {
    let params = new HttpParams();
    if (limit > -1) {
      params = params.append('take', limit.toString());
    }
    if (offset > -1) {
      params = params.append('offset', offset.toString());
    }
    if (sort) {
      params = params.append('sort', sort);
    }
    if (search) {
      params = params.append('search', search.toString());
    }
    if (include) {
      params = params.append('include', include);
    }
    if (mt) { // for dashboard assets
      params = params.append('mt', mt); // for dashboard assets
    } // for dashboard assets
    if (where) {
      params = this.toHttpParams(where, params, 'where');
    } if (status) {
      params = params.append('status', status.toString());
    }
    return params;
  }
  parseSort(active, direction): string {
    return `${direction === 'asc' ? '' : '-'}${active}`;
  }
  generateError(errors): string {
    let errMessage = '';
    if (errors) {
      errors.forEach((e) => {
        errMessage += `${e.param}: ${e.msg}. `;
      });
    }
    return errMessage;
  }
  toHttpParams(obj, params, type): HttpParams {
    const keys = Object.keys(obj);
    if (keys.length) {
      keys.forEach((key) => {
        params = params.append(`${type}[${key}]`, obj[key]);
      });
    }
    return params;
  }

  isInvalid(form: FormGroup, control: string): boolean {
    return (
      form.get(control).invalid &&
      (form.get(control).dirty || form.get(control).touched)
    );
  }
  exportAsExcelFile(json: any[], filename: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    XLSX.writeFile(workbook, HelpersService.toExportFileName(filename), { bookType: 'xlsx', type: 'binary' });
  }
  getDuration(start_date: Date, end_date: Date, localization: string) {
    const start = moment(start_date, 'YYYY-MM-DD');
    const end = moment(end_date, 'YYYY-MM-DD');
    const diff = moment.preciseDiff(start, end)
    const isEnglish = localization == 'en';

    if (isEnglish) return diff;

    const regex = /years|year|months|month|days|day/gi;
    const surrogate = {
      years: 'Tahun',
      year: 'Tahun',
      months: 'Bulan',
      month: 'Bulan',
      days: 'Hari',
      day: 'Hari'
    }

    return diff.replace(regex, (matched) => surrogate[matched]);
  }

  parseImageUrl(url) {
    return isDevMode() ? environment.host + '/' + url : url;
  }

  getDownloadedFileName(contentDispositionValue: string): string {
    const result = contentDispositionValue.split(';')[1].trim().split('=')[1];
    return result.replace(/"/g, '');
  }

  getMonthBetween(start_date: Date, end_date: Date){
    const start = moment(start_date, 'YYYY-MM-DD');
    const end = moment(end_date, 'YYYY-MM-DD');

    var startYear: number = parseInt(start.format('YYYY'));
    var endYear: number = parseInt(end.format('YYYY'));

    var yearValues = [];
    var monthValues = {};
    for (let year = startYear; year <= endYear; year++) {
      yearValues.push(year);
      monthValues[year] = [];
    }

    while (end > start || start.format('M') === end.format('M')) {
      monthValues[start.format('YYYY')].push(start.format('YYYYMM'));
      start.add(1, 'month');
    }

    var flat = Object.values(monthValues);
    var merged = [].concat.apply([], flat);

    return { year: yearValues, month: monthValues, merged:merged};
  }

  getMonthAdded(date: Date, no_of_months: any) {
    const start = moment(date, 'YYYY-MM-DD');
    const end = moment(date, 'YYYY-MM-DD').add(no_of_months-1, 'month');
    var end_date = new Date(end.format('YYYY-MM-DD'));

    return this.getMonthBetween(date, end_date);
  }

  dateToMonthName(date: Date){
    const dt = moment(date, 'YYYYMM');
    return dt.format('MMMM');
  }

  totalBebanJasa({data, length}) {
    var totalBebanJasa = 0;
    data.forEach(a => {
      if (a.is_service_charge === true) {
        totalBebanJasa += a.total
      }
    })

    return totalBebanJasa;
  }

  countObject(obj){
    if (typeof obj === 'object' && obj !== null) {
      var keys = Object.keys(obj);
      var len = keys.length;
      return len;
    }
    return 0;
  }

  cleanObject(obj) {
    const newObj = {};
    Object.entries(obj).forEach(([k, v]) => {
      if (v === Object(v)) {
        newObj[k] = this.cleanObject(v);
      } else if (v != null) {
        newObj[k] = obj[k];
      }
    });
    return newObj;
  }
}
