import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full'
  },
  {
    path: 'login',
    loadChildren: () => import('./authentication/login-2/login-2.module').then(m => m.Login2Module)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./authentication/forgot-password-2/forgot-password-2.module').then(m => m.ForgotPassword2Module)
  },
  {
    path: 'reset-password',
    loadChildren: () => import('./authentication/reset-password-2/reset-password-2.module').then(m => m.ResetPassword2Module)
  },
  {
    path: 'mail-confirm',
    loadChildren: () => import('./authentication/mail-confirm/mail-confirm.module').then(m => m.MailConfirmModule)
  },
  {
    path: 'errors/error-404',
    loadChildren: () => import('./errors/404/error-404.module').then(m => m.Error404Module)
  },
  {
    path: 'errors/error-500',
    loadChildren: () => import('./errors/500/error-500.module').then(m => m.Error500Module)
  },
  {
    path: 'maintenance',
    loadChildren: () => import('./maintenance/maintenence.module').then(m => m.MaintenanceModule)
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
  },
  {
    path: 'access',
    loadChildren: () => import('./access/access.module').then(m => m.AccessModule)
  },
  {
    path: 'users',
    loadChildren: () => import('./users/users.module').then(m => m.UsersModule)
  },
  {
    path: 'settings',
    loadChildren: () => import('./site-settings/site-settings.module').then(m => m.SiteSettingsModule)
  },
  {
    path: 'projects',
    loadChildren: () => import('./projects/projects/projects.module').then(m => m.ProjectsModule)
  },
  {
    path: 'calendar',
    loadChildren: () => import('./projects/calendar/calendar.module').then(m => m.CalendarModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('./projects/jobs/jobs.module').then(m => m.JobsModule)
  },

  {
    path: 'payments',
    loadChildren: () => import('./monetary/payments/payments.module').then(m => m.PaymentsModule)
  },
  {
    path: 'earnings',
    loadChildren: () => import('./monetary/earnings/earnings.module').then(m => m.EarningsModule)
  },
  {
    path: 'credits',
    loadChildren: () => import('./monetary/credits/credits.module').then(m => m.CreditsModule)
  },
  {
    path: 'subscriptions',
    loadChildren: () => import('./monetary/subscriptions/subscriptions.module').then(m => m.SubscriptionsModule)
  },
  {
    path: 'menus',
    loadChildren: () => import('./menus/menus.module').then(m => m.MenusModule)
  },
  {
    path: 'logs',
    loadChildren: () => import('./logs/logs.module').then(m => m.LogsModule)
  },
  {
    path: 'bank-accounts',
    loadChildren: () => import('./banks/banks.module').then(m => m.BanksModule)
  },
  {
    path: 'assets',
    loadChildren: () => import('./assets/assets.module').then(m => m.AssetsModule)
  },
  {
    path: 'customers',
    loadChildren: () => import('./customers/customers.module').then(m => m.CustomersModule)
  },
  {
    path: 'job-position',
    loadChildren: () => import('./job-position/job-position.module').then(m => m.JobPositionModule)
  },
  {
    path: 'company-profile',
    loadChildren: () => import('./company-profile/company-profile.module').then(m => m.CompanyProfileModule)
  },
  {
    path: 'invoices',
    loadChildren: () => import('./monetary/invoices/invoices.module').then(m => m.InvoicesModule)
  },
  {
    path: 'invoices-draft',
    loadChildren: () => import('./monetary/invoices/invoices-draft/invoices-draft.module').then(m => m.InvoicesDraftModule)
  },
  {
    path: 'invoices-restore',
    loadChildren: () => import('./monetary/invoices/invoices-restore/invoices-restore.module').then(m => m.InvoicesRestoreModule)
  },
  {
    path: 'lock',
    loadChildren: () => import('./authentication/lock/lock.module').then(m => m.LockModule)
  },
  {
    path: 'regions',
    loadChildren: () => import('./regions/regions.module').then(m => m.RegionsModule)
  },
  {
    path: 'roles',
    loadChildren: () => import('./roles/roles.module').then(m => m.RolesModule)
  },
  {
    path: 'privileges',
    loadChildren: () => import('./privileges/privileges.module').then(m => m.PrivilegesModule)
  },
  {
    path: 'vendors',
    loadChildren: () => import('./vendors/vendors.module').then(m => m.VendorsModule)
  },
  {
    path: 'backup',
    loadChildren: () => import('./backup/backup.module').then(m => m.BackupModule)
  },
  {
    path: 'coa',
    loadChildren: () => import('./coa/coa.module').then(m => m.CoaModule)
  },
  {
    path: 'under-maintenance',
    loadChildren: () => import('./errors/under-maintenance/under-maintenance.module').then(m => m.UnderMaintenanceModule)
  },
  {
    path: 'auctions',
    loadChildren: () => import('./auctions/auctions.module').then(m => m.AuctionsModule)
  },
  {
    path: 'auctions-monitor',
    loadChildren: () => import('./auctions/auctions-monitor/auctions-monitor.module').then(m => m.AuctionsMonitorModule)
  },
  { path: 'partners', loadChildren: () => import('./partners/partners.module').then(m => m.PartnersModule) },
  { path: 'employees', loadChildren: () => import('./employees/employees.module').then(m => m.EmployeesModule) },
  { path: 'master-employee', loadChildren: () => import('./master-employee/master-employee.module').then(m => m.MasterEmployeeModule) },
  { path: 'employee-monitor', loadChildren: () => import('./employee-monitor/employee-monitor.module').then(m => m.EmployeeMonitorModule) },
  { path: 'employee-chart', loadChildren: () => import('./employee-chart/employee-chart.module').then(m => m.EmployeeChartModule) },
  { path: 'notifications', loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule) },
  {
    path: '**',
    redirectTo: 'errors/error-404'
  }
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules, relativeLinkResolution: 'legacy' })
  ],
  declarations: [],
  exports: [
    RouterModule
  ]
})
export class PagesModule {

}
