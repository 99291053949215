<h2 mat-dialog-title>Catatan</h2>
<div fxLayout="column" fxLayoutAlign="center center">
  <div mat-dialog-content class="mat-typography">
    {{data.note}}
  </div>
  <div mat-dialog-actions>
    <div fxLayout="row">
      <button fxFlex="50" mat-flat-button type="button" (click)="dialogRef.close()">{{ 'CONFIRM.CANCEL' |
        translate}}</button>
    </div>
  </div>
</div>