<div fxLayout="column" fxLayoutAlign="center center">
  <div mat-dialog-content>
    {{ data?.message ? data.message : 'CONFIRM.MESSAGE' | translate}}
  </div>
  <div mat-dialog-actions>
    <div fxLayout="row">
      <button fxFlex="50" mat-flat-button type="button" (click)="dialogRef.close()">{{ data?.no ? data.no : 'CONFIRM.CANCEL' | translate}}</button>
      <button fxFlex="50" mat-flat-button  type="button" (click)="dialogRef.close(true)" color="warn">{{ data?.yes ? data.yes : 'CONFIRM.CONFIRM' | translate}}</button>
    </div>
  </div>
</div>
