export const locale = {
  lang: 'id',
  data: {
    'NAV': {
      'AUCTION': {
        'GROUP': 'Manajemen Lelang',
        'AUCTION': 'Kelola Lelang',
        'MONITOR': 'Pemantauan Lelang',
        'SDM': 'SDM Lelang',
        'SDM_MONITOR': 'Pemantauan SDM Lelang',
        'SDM_CHART': 'Chart SDM Lelang',
        'ALL': 'Semua Lelang'
      },
      'PROJECT': {
        'DASHBOARD': 'Dashboard Proyek',
        'GROUP': 'Manajemen Proyek',
        'TITLE': 'Daftar Proyek',
        'CALENDAR': 'Kalender Kegiatan',
        'ALL': 'Semua Proyek',
        'ONPROGRESS': 'Progress Proyek',
        'PLANNED': 'Rencana',
        'PROGRESS': 'Berlangsung',
        'DONE': 'Selesai',
        'CLOSED': 'Lunas',
        'CANCELED': 'Batal',
        'ONHOLD': 'Ditunda'
      },
      'MONETARY': {
        'GROUP': 'Manajemen Keuangan',
        'MENU': 'Pengaturan Menu',
        'EARNINGS': 'Pendapatan Tahunan',
        'CREDIT': 'Piutang',
        'SUBSCRIPTION': 'Langganan',
        'INVOICE': {
          'LIST': 'Daftar Penagihan',
          'SAVED': 'Penagihan',
          'DRAFT': 'Konsep',
          'RESTORE': 'Pulihkan'
        },
        'PAYMENT': 'Daftar Pembayaran'
      },
      'ASSETS': {
        'GROUP': 'Manajemen Logistik',
        'DASHBOARD': 'Dashboard Aset',
        'APPROVAL': 'Persetujuan Aset',
        'APPROVAL_ADD': 'Penambahan',
        'APPROVAL_RENT': 'Peminjaman',
        'APPROVAL_RETURN': 'Pengembalian',
        'APPROVAL_DISCONTINUE': 'Pemberhentian',
        'APPROVAL_MAINTENANCE': 'Perbaikan',
        'APPROVAL_MAINTENANCE_RETURN': 'Pengembalian Perbaikan',
        'MASTER': 'Daftar Aset',
        'RENTAL': 'Peminjaman Aset',
        'RETURN': 'Pengembalian Aset',
        'DISCONTINUE': 'Pemberhentian Aset',
        'CATEGORY': 'Kategori Aset',
        'MAINTENANCE': 'Perbaikan Aset',
        'CONDITION': 'Kondisi Aset',
      },
      'MASTER': {
        'GROUP': 'Data Induk',
        'COA': 'COA',
        'CUSTOMER': 'Daftar Pelanggan',
        'PARTNER': 'Daftar Mitra',
        'VENDOR': 'Daftar Vendor',
        'BANK': 'Daftar Rekening Bank',
        'REGIONS': {
          'TITLE': 'Daftar Wilayah',
          'PROVINCE': 'Provinsi',
          'CITY': 'Kabupaten/Kota',
          'DISTRICT': 'Kecamatan',
          'SUBDISTRICT': 'Kelurahan'
        },
        'USERS': {
          'TITLE': 'Daftar Pengguna',
          'ALL': 'Semua Pengguna',
          'PROJECT': 'Bidang Proyek',
          'MONETARY': 'Bidang Keuangan',
          'LOGISTIC': 'Bidang Logistik',
        },
        'SDM': {
          'TITLE': 'SDM Lelang',
          'EMPLOYEE_STATUS': 'Status Kepegawaian',
          'COMPENSATION': 'Jenis Kompensasi',
          'MARRIAGE_STATUS': 'Status Pernikahan',
          'MAJOR': 'Jurusan',
          'REASON': 'Alasan Pemberhentian',
          'PLAFON': 'Tipe Plafon',
          'POSITION': 'Jabatan',
        },
        'JOBPOSITION': 'Daftar Jabatan',
        'PRIVILEGE': 'Daftar Kewenangan',
        'ROLES': 'Grup Akses',
        'ACCESS': 'Hak Akses',
        'PRIVILEGES': 'Hak Istimewa Pengguna',
        'SETTING': {
          'TITLE': 'Pengaturan',
          'COMPANY': 'Profil Perusahaan',
          'SITE': 'Pengaturan Situs',
          'MENU': 'Pengaturan Menu',
          'BACKUP': 'Pencadangan Data'
        },
        'LOGS': {
          'TITLE': 'Daftar Log',
          'SYSTEM': 'Log Sistem',
          'ACTIVITY': 'Log Aktifitas',
        },
      },
    }
  }
};
