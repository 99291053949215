<span *ngIf="isParsed" class="font-weight-500" [ngClass]="{'green-fg': sign(data) == 1, 'warn-fg': sign(data) == -1}">
  <ng-container *ngIf="!noSymbol">
    {{ data | currency:'IDR':'Rp ':'1.0-0':'id'}}
  </ng-container>
  <ng-container *ngIf="noSymbol">
    <span [ngClass]="{'red-fg':isOverprice(data, price)}">
      {{ data | currency:'IDR':'':'1.0-0':'id'}}
    </span>
  </ng-container>
</span>
<span *ngIf="!isParsed">
  {{ data }}
</span>
