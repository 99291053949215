<button type="button" mat-icon-button [matMenuTriggerFor]="actionMenu">
  <mat-icon>more_vert</mat-icon>
</button>
<mat-menu #actionMenu="matMenu" xPosition="before">
  <ng-container *ngIf="withDetail">
    <button type="button" mat-menu-item (click)="onDetail(row)">
      <span class="fa-stack mr-4">
        <span class="fas fa-fw fa-circle fa-stack-2x cyan-50-fg"></span>
        <span class="fas fa-fw fa-search fa-stack-1x cyan-600-fg"></span>
      </span>
      <span>Detail</span>
    </button>
  </ng-container>
  <ng-container *ngIf="withEdit">
    <button type="button" mat-menu-item (click)="onEdit(row)">
      <span class="fa-stack mr-4">
        <span class="fas fa-fw fa-circle fa-stack-2x amber-50-fg"></span>
        <span class="fas fa-fw fa-pencil-alt fa-stack-1x amber-600-fg"></span>
      </span>
      <span>{{'EDIT' | translate}}</span>
    </button>
  </ng-container>
  <ng-container *ngIf="!disableDelete">
    <button type="button" mat-menu-item (click)="onRemove(row)">
      <span class="fa-stack mr-4">
        <span class="fas fa-fw fa-circle fa-stack-2x warn-50-fg"></span>
        <span class="far fa-fw fa-trash-alt fa-stack-1x warn-600-fg"></span>
      </span>
      <span>{{'REMOVE' | translate}}</span>
    </button>
  </ng-container>
</mat-menu>
