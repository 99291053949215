import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { ShowNoteDialogComponent } from './show-note-dialog.component'
import { MatButtonModule } from '@angular/material/button'
import { MatDialogModule } from '@angular/material/dialog'
import { MatIconModule } from '@angular/material/icon'
import { FlexLayoutModule } from '@angular/flex-layout'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  declarations: [ShowNoteDialogComponent],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatDialogModule,
    MatButtonModule,
    MatIconModule,
    TranslateModule
  ],
  exports: [
    ShowNoteDialogComponent
  ],
  entryComponents: [ShowNoteDialogComponent]
})
export class ShowNoteDialogModule { }
