import { Component, Inject } from '@angular/core'
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog'
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service'
import { locale as english } from './i18n/en'
import { locale as indonesia } from './i18n/id'

export interface DialogData {
  note: string
}

@Component({
  selector: 'app-show-note-dialog',
  templateUrl: './show-note-dialog.component.html',
  styleUrls: ['./show-note-dialog.component.scss']
})

export class ShowNoteDialogComponent {
  constructor(public dialogRef: MatDialogRef<ShowNoteDialogComponent>,
    private _fuseTranslationLoader: FuseTranslationLoaderService,
    @Inject(MAT_DIALOG_DATA) public data: DialogData
  ) {
    this._fuseTranslationLoader.loadTranslations(english, indonesia)
  }
}
