import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { BaseAPIObject, BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { Menus } from './menus';

@Injectable({
  providedIn: 'root'
})
export class MenusService extends BaseApiService<Menus>{
  mainMenu$ = new BehaviorSubject(true);
  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'menus';
  }
  main(): Observable<BaseAPIObject<Menus>> {
    const url = this.url_custom('main');
    return this.http.get<BaseAPIObject<Menus>>(url);
  }
}
