<mat-toolbar class="p-0">

  <div fxFlex fxFill fxLayout="row" fxLayoutAlign="start center">

    <div fxFlex="1 0 auto" fxLayout="row" fxLayoutAlign="start center">

      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && !rightNavbar"
        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && !rightNavbar" fxHide.gt-md></div>

      <div fxLayout="row" fxLayoutAlign="start center" *ngIf="horizontalNavbar">
        <div class="logo ml-16">
          <img class="logo-icon" src="assets/images/avatars/avatar.webp">
        </div>
      </div>

      <div class="px-8 px-md-16">
        <!-- <fuse-shortcuts [navigation]="navigation"></fuse-shortcuts> -->
      </div>

    </div>

    <div class="" fxFlex="0 1 auto" fxLayout="row" fxLayoutAlign="start center">

      <button mat-button [matMenuTriggerFor]="userMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="avatar mr-0 mr-sm-16" [src]="helper.parseImageUrl(currentUser.avatar)" *ngIf="currentUser && currentUser.avatar">
          <img class="avatar mr-0 mr-sm-16" src="assets/images/avatars/avatar.webp"
            *ngIf="currentUser && !currentUser.avatar">
          <span class="username mr-12" fxHide fxShow.gt-sm *ngIf="currentUser"> {{ currentUser.fullname }}</span>
          <mat-icon class="s-16" fxHide.xs>keyboard_arrow_down</mat-icon>
        </div>
      </button>

      <mat-menu #userMenu="matMenu" [overlapTrigger]="false">

        <button mat-menu-item [routerLink]="'/users/'+currentUser.id+'/profile'"  *ngIf="currentUser">
          <span class="fa-stack mr-4">
            <span class="fas fa-fw fa-circle fa-stack-2x indigo-50-fg"></span>
            <span class="fas fa-fw fa-user fa-stack-1x indigo-600-fg"></span>
          </span>
          <span>{{ 'TOOLBAR.MENU.PROFILE' | translate}}</span>
        </button>
        <button mat-menu-item (click)="logout()">
          <span class="fa-stack mr-4">
            <span class="fas fa-fw fa-circle fa-stack-2x warn-50-fg"></span>
            <span class="fas fa-fw fa-sign-out-alt fa-stack-1x warn-600-fg"></span>
          </span>
          <span>{{ 'TOOLBAR.MENU.LOGOUT' | translate}}</span>
        </button>

      </mat-menu>

      <div class="toolbar-separator"></div>

      <button mat-button [matMenuTriggerFor]="notificationMenu" class="user-button">
        <div fxLayout="row" fxLayoutAlign="center center">
          <mat-icon class="secondary-text" [matBadge]="unreadNotifications" matBadgeColor="warn">notifications</mat-icon>
        </div>
      </button>

      <mat-menu #notificationMenu="matMenu" [overlapTrigger]="false" class="notifications">

        <div class="notif-wrapper">
          <div class="notif-title">
            <h3 class="font-weight-700">Notifikasi</h3>
          </div>
          <div class="notif-content">
            <div
            mat-menu-item
            class="notif-item"
            [class.unread]="!notif.is_read"
            *ngFor="let notif of appNotifications"
            (click)="openNotification(notif)">
              <span [innerHTML]="parseDesc(notif.desc, notif)"></span>
              <span class="d-block text-right text-secondary font-size-12">
                {{ notif.created_at | date:'dd-MM-yyyy':null:'id' }} {{ notif.created_at | date:'shortTime':'id-ID':'id' }}
              </span>
            </div>
          </div>
          <button mat-menu-item (click)="showAllNotifications()">
            <a routerLink="/notifications" class="text-center d-block blue-600-fg font-weight-700">
              <span class="text-center d-block">{{ 'TOOLBAR.MENU.NOTIFICATION_ALL' | translate}}</span>
            </a>
          </button>
        </div>
      </mat-menu>

      <div class="toolbar-separator"></div>

      <fuse-search-bar (searchChange)="search($event)"></fuse-search-bar>

      <div class="toolbar-separator"></div>

      <button mat-button fxHide fxShow.gt-xs class="language-button" [matMenuTriggerFor]="languageMenu" *ngIf="selectedLanguage">
        <div fxLayout="row" fxLayoutAlign="center center">
          <img class="flag mr-8" [src]="'assets/icons/flags/'+selectedLanguage.flag+'.png'">
          <span class="iso text-uppercase">{{selectedLanguage.id}}</span>
        </div>
      </button>

      <mat-menu #languageMenu="matMenu" [overlapTrigger]="false">

        <button mat-menu-item *ngFor="let lang of languages" (click)="setLanguage(lang)">
          <span fxLayout="row" fxLayoutAlign="start center">
            <img class="flag mr-16" [src]="'assets/icons/flags/'+lang.flag+'.png'">
            <span class="iso">{{lang.title}}</span>
          </span>
        </button>

      </mat-menu>

      <!-- <div class="toolbar-separator" fxHide fxShow.gt-xs></div> -->

      <!-- <button mat-icon-button class="quick-panel-toggle-button" (click)="toggleSidebarOpen('quickPanel')"
        aria-label="Toggle quick panel">
        <mat-icon class="secondary-text">format_list_bulleted</mat-icon>
      </button> -->

      <div class="toolbar-separator" *ngIf="!hiddenNavbar && rightNavbar" fxHide fxShow.gt-xs></div>

      <button mat-icon-button class="navbar-toggle-button" *ngIf="!hiddenNavbar && rightNavbar"
        (click)="toggleSidebarOpen('navbar')" fxHide.gt-md>
        <mat-icon class="secondary-text">menu</mat-icon>
      </button>

    </div>

  </div>

</mat-toolbar>
