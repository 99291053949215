import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { HelpersService } from '../helpers.service';
import { BaseAPIObject, BaseApiService } from '../base-api.service';
import { AppNotification } from './notification';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AppNotificationService extends BaseApiService<AppNotification> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'notifications';
  }

  markAsRead(id): Observable<BaseAPIObject<AppNotification>> {
    return this.updateById(id, { is_read: true });
  }
}
