import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { LoginResponse } from './login';
import { JwtHelperService } from '@auth0/angular-jwt';
import { AccountAccesses, Users } from './users';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http: HttpClient,
    private router: Router,
    public jwtHelper: JwtHelperService
  ) { }
  private localToken = 'pmo-token';
  private localUser = 'pmo-user';
  auth(username: string, password: string, rememberMe: boolean): Observable<LoginResponse> {
    const auth = 'Basic ' + btoa(`${username}:${password}`);
    const headers = new HttpHeaders().append('Authorization', auth).append('X-Localization', 'id');
    const url = '/api/auth/login';
    return this.http.post<LoginResponse>(url, { rememberMe }, { headers });
  }
  logout(redirectToLogin?: boolean): void {
    localStorage.clear();
    if (redirectToLogin) {
      this.router.navigate(['/login']);
    }
    //
  }
  invalidateToken(): Observable<LoginResponse> {
    const url = '/api/auth/logout';
    return this.http.post<LoginResponse>(url, {});
  }
  getToken(): string {
    return localStorage.getItem(this.localToken);
  }
  setToken(token: string): void {
    localStorage.setItem(this.localToken, token);
  }
  getLoggedUser(): Users {
    const userSession = localStorage.getItem(this.localUser);
    return (userSession) ? JSON.parse(userSession) : null;
  }
  isSuperadmin(userAccesses: AccountAccesses[]): boolean {
    return userAccesses.map(access => access.role.name).includes('superadmin');
  }
  isLogisticUser(userAccesses: AccountAccesses[]): boolean {
    return userAccesses.map(access => access.role.name).includes('logistic');
  }
  isProjectUser(userAccesses: AccountAccesses[]): boolean {
    return userAccesses.map(access => access.role.name).includes('project');
  }
  isMonetaryUser(userAccesses: AccountAccesses[]): boolean {
    return userAccesses.map(access => access.role.name).includes('monetary');
  }
  isAccesName(userAccesses: AccountAccesses[], name = ''): boolean {
    return userAccesses.map(access => access.access.name).includes(name);
  }
  isProjectTender(privileges): boolean {
    return privileges.includes('PRJ-IMPORT');
  }
  isProjectManager(privileges): boolean {
    return privileges.includes('PRJ-MANAGER');
  }
  isProjectViewer(privileges): boolean {
    return privileges.includes('PRJ-VIEWER');
  }
  isProjectWorker(privileges): boolean {
    return privileges.includes('PRJ-WORKER');
  }
  isLogisticManager(privileges): boolean {
    return privileges.includes('LOG-MANAGER');
  }
  isLogisticOperator(privileges): boolean {
    return privileges.includes('LOG-OPERATOR');
  }
  isAuctionManager(privileges): boolean {
    return privileges.includes('AUCTION-MGR');
  }
  isAuctionAdmin(privileges): boolean {
    return privileges.includes('AUCTION');
  }
  isSdmPik(privileges): boolean {
    return privileges.includes('SDM-PIK');
  }
  setLoggedUser(user: Users): void {
    localStorage.setItem(this.localUser, JSON.stringify(user));
  }
  forgotPassword(email: string): Observable<any> {
    const body = { credential: email };
    return this.http.post<any>('/api/auth/forgot-password', body);
  }
  restorePassword(data): Observable<any> {
    const url = "/api/auth/restore-password";
    return this.http.post<any>(url, data);
  }
  public isAuthenticated(): boolean {
    return !this.jwtHelper.isTokenExpired(this.getToken());
  }

}
