import { Component, Input, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as en } from './i18n/en';
import { locale as id } from './i18n/id';

@Component({
  selector: 'app-asset-approval-status',
  templateUrl: './asset-approval-status.component.html',
  styleUrls: ['./asset-approval-status.component.scss']
})
export class AssetApprovalStatusComponent implements OnInit {

  @Input() assetApprovalStatusName: boolean;
  assetApprovalStatus: string;

  constructor(
    public trans: TranslateService,
    private fuseTranslationLoader: FuseTranslationLoaderService
  ) {
    this.fuseTranslationLoader.loadTranslations(en, id);
  }

  ngOnInit(): void {
    this.setAssetApprovalStatus();
  }

  setAssetApprovalStatus() {
    if (this.assetApprovalStatusName == true) {
      this.assetApprovalStatus = 'approved'
    }
    else if(this.assetApprovalStatusName == false) {
      this.assetApprovalStatus = 'refused'
    }
    else {
      this.assetApprovalStatus = 'waiting'
    }
  }

}
