export const locale = {
  lang: 'id',
  data: {
    'TOOL': {
      'REMOVE': {
        'ALL': 'Hapus Semua',
        'SELECTED': 'Hapus Terpilih'
      },
      'RETURN': {
        'SELECTED': 'Kembalikan terpilih'
      },
      'FILTER': 'Saring'
    }
  }
};
