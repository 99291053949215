import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthService } from 'app/main/services/auth.service';
import { LangStorageService } from 'app/main/services/lang.service';

@Injectable({
  providedIn: 'root'
})
export class JwtInterceptorService implements HttpInterceptor {
  constructor(private auth: AuthService, private langStorage: LangStorageService) {}
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // console.log(req.url, req.url.endsWith('login'));
    if (!req.url.endsWith('login')) {
      req = req.clone({
        setHeaders: {
          'Authorization': `Bearer ${this.auth.getToken()}`,
          'X-Localization' : this.langStorage.getLangStorage()
        }
      });
    }
    return next.handle(req);
  }
}
