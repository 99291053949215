export const locale = {
  lang: 'en',
  data: {
    'TOOL': {
      'REMOVE': {
        'ALL': 'Remove All',
        'SELECTED': 'Remove Selected'
      },
      'RETURN': {
        'SELECTED': 'Return Selected'
      },
      'FILTER': 'Filter'
    }
  }
};
