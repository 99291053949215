export const locale = {
  lang: 'id',
  data: {
    'TOOLBAR': {
      'MENU': {
        'PROFILE': 'Profil Saya',
        'LOGOUT': 'Keluar',
        'NOTIFICATION_ALL': 'Tampilkan Semua',
        'MARK_AS_READ': 'Tandai telah dibaca',
        'MARK_ALL_AS_READ': 'Tandai semua telah dibaca'
      },
    }
  }
};
