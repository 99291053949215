export const locale = {
  lang: 'en',
  data: {
    'NAV': {
      'AUCTION': {
        'GROUP': 'Auction Management',
        'AUCTION': 'Auction List',
        'MONITOR': 'Auction Monitoring',
        'SDM': 'Auction Employee',
        'SDM_MONITOR': 'Employee Monitoring',
        'SDM_CHART': 'Employee Chart',
        'ALL': 'All Auction'
      },
      'PROJECT': {
        'DASHBOARD': 'Project Dashboard',
        'GROUP': 'Project Management',
        'TITLE': 'Project List',
        'CALENDAR': 'Activity Calendar',
        'ALL': 'All Project',
        'ONPROGRESS': 'Job on Progress',
        'PLANNED': 'Planned',
        'PROGRESS': 'Progress',
        'DONE': 'Done',
        'CLOSED': 'Closed',
        'CANCELED': 'Canceled',
        'ONHOLD': 'On Hold'
      },
      'MONETARY': {
        'GROUP': 'Monetary Management',
        'EARNINGS': 'Yearly Earnings',
        'CREDIT': 'Receivable Earnings',
        'SUBSCRIPTION': 'Subscription',
        'INVOICE': {
          'LIST': 'Invoice List',
          'SAVED': 'Invoice',
          'DRAFT': 'Draft',
          'RESTORE': 'Restore'
        },
        'PAYMENT': 'Payment List'
      },
      'ASSETS': {
        'GROUP': 'Logistic Management',
        'DASHBOARD': 'Assets Dashboard',
        'APPROVAL': 'Assets Approval',
        'APPROVAL_ADD': 'Addition Approval',
        'APPROVAL_RENT': 'Rent Approval',
        'APPROVAL_RETURN': 'Returning Approval',
        'APPROVAL_DISCONTINUE': 'Discontinue Approval',
        'APPROVAL_MAINTENANCE': 'Maintenance Approval',
        'APPROVAL_MAINTENANCE_RETURN': 'Maintenance Return Approval',
        'MASTER': 'Assets List',
        'RENTAL': 'Assets Rental',
        'RETURN': 'Assets Return',
        'DISCONTINUE': 'Assets Discontinue',
        'CATEGORY': 'Assets Category',
        'MAINTENANCE': 'Assets Maintenance',
        'CONDITION': 'Assets Condition',
      },
      'MASTER': {
        'GROUP': 'Master Data',
        'COA': 'COA',
        'CUSTOMER': 'Customer',
        'PARTNER': 'Partner',
        'VENDOR': 'Vendor',
        'BANK': 'Bank Account',
        'REGIONS': {
          'TITLE': 'Regions',
          'PROVINCE': 'Province',
          'CITY': 'City',
          'DISTRICT': 'District',
          'SUBDISTRICT': 'Sub Distric'
        },
        'USERS': {
          'TITLE': 'Users',
          'ALL': 'All Users',
          'PROJECT': 'Project User',
          'MONETARY': 'Monetary User',
          'LOGISTIC': 'Logistic User',
        },
        'SDM': {
          'TITLE': 'Auction SDM',
          'EMPLOYEE_STATUS': 'Employee Status',
          'COMPENSATION': 'Compensation Type',
          'MARRIAGE_STATUS': 'Marriage Status',
          'MAJOR': 'Major',
          'REASON': 'Termination Reason',
          'PLAFON': 'Plafon Type',
          'POSITION': 'Position',
        },
        'JOBPOSITION': 'Job Position',
        'PRIVILEGE': 'Role Privilege',
        'ROLES': 'Roles',
        'ACCESS': 'User Access',
        'SETTING': {
          'TITLE': 'Settings',
          'COMPANY': 'Company Profile',
          'SITE': 'Site Settings',
          'MENU': 'Menu Settings',
          'BACKUP': 'Backup Database'
        },
        'LOGS': {
          'TITLE': 'Logs',
          'SYSTEM': 'System Logs',
          'ACTIVITY': 'Activity Logs',
        },
      },
    }
  }
};
