import { Directive } from '@angular/core';
import { AbstractControl, AsyncValidator, NG_ASYNC_VALIDATORS, ValidationErrors } from '@angular/forms';
import { Observable, of } from 'rxjs';

@Directive({
  selector: '[appOldnewPasswordValidator]',
  providers: [{
    provide: NG_ASYNC_VALIDATORS, useExisting: OldnewPasswordValidatorDirective,
    multi: false
  }]
})
export class OldnewPasswordValidatorDirective implements AsyncValidator {
  constructor() { }
  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (!control.value.new_pwd && !control.value.new_pwd_confirmation) {
      return null;
    }
    return of(control.value.new_pwd !== control.value.new_pwd_confirmation ? { diffPass: true } : null);
  }
}
