<!-- <mat-toolbar>

    <div fxLayout="row" fxLayoutAlign="center center" fxLayoutAlign.gt-xs="space-between center" fxFlex>
      <div></div>
      <mat-paginator fxFlex="100" #paginator id="pagination" [pageSize]="50" showFirstLastButtons [length]="length" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
    </div>

</mat-toolbar> -->

<mat-paginator fxFlex="100" #paginator id="pagination" [pageSize]="5" showFirstLastButtons [length]="length" [pageSizeOptions]="pageSizeOptions" (page)="onPageChange($event)"></mat-paginator>
