import { Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';

@Component({
  selector: 'app-actions-column',
  templateUrl: './actions-column.component.html',
  styleUrls: ['./actions-column.component.scss']
})
export class ActionsColumnComponent implements OnInit {

  constructor() { }
  @Input() row: any;
  @Input() withDetail = false;
  @Input() disableDelete = false;
  @Input() withEdit = true;
  @Output() edit = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() detail = new EventEmitter();
  ngOnInit(): void {
  }

  onDetail(row): void{
    this.detail.emit(row);
  }

  onEdit(row): void{
    this.edit.emit(row);
  }

  onRemove(row): void {
    this.remove.emit(row);
  }
}
