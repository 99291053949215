export const locale = {
  lang: 'en',
  data: {
      'CONFIRM': {
          'MESSAGE': 'Are you sure?',
          'CANCEL' : 'Cancel',
          'CONFIRM' : 'SAVE'
      }
  }
};
