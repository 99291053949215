export const locale = {
  lang: 'en',
  data: {
    'TOOLBAR': {
      'MENU': {
        'PROFILE': 'My Profile',
        'LOGOUT': 'Logout',
        'NOTIFICATION_ALL': 'Show All',
        'MARK_AS_READ': 'Mark as read',
        'MARK_ALL_AS_READ': 'Mark all as read'
      },
    }
  }
};
