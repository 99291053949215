<div fxLayout="column" fxLayoutAlign="center center">
  <div mat-dialog-content>
    <mat-form-field class="example-full-width" appearance="fill">
      <mat-label>Catatan</mat-label>
      <textarea matInput [(ngModel)]="val"></textarea>
    </mat-form-field>
  </div>
  <div mat-dialog-actions>
    <div fxLayout="row">
      <button fxFlex="50" mat-flat-button type="button" (click)="dialogRef.close()">{{ 'CONFIRM.CANCEL' |
        translate}}</button>
      <button fxFlex="50" mat-flat-button type="button" (click)="dialogRef.close(val)" color="warn">{{
        'CONFIRM.CONFIRM' | translate}}</button>
    </div>
  </div>
</div>