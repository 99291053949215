import { HttpClient, HttpParams, HttpResponse } from '@angular/common/http';
import { Injectable, isDevMode} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { saveAs } from 'file-saver-es';

import { environment } from 'environments/environment';
import { BaseAuctionAPIArray } from './auction';
import { BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { HttpQuery } from './http-query';
import { Projects } from './projects';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuctionService extends BaseApiService<Projects> {

  filterKriteria$ = new BehaviorSubject({});
  deleteSelected$ = new BehaviorSubject([]);
  removeAll$ = new BehaviorSubject(false);
  add$ = new BehaviorSubject(false);

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'projects';
  }

  getWhere(where, query?: HttpQuery): Observable<BaseAuctionAPIArray<Projects>> {
    const url = this.url_custom('filter-auction');
    const params = query ? this.helper.generateParams(query) : {};
    return this.http.post<BaseAuctionAPIArray<Projects>>(url, where, { params });
  }

  exportPdf(id: number[], type: 'job_desc' | 'job_related' | 'job_last_ten_years' | 'auction_list' = 'job_desc'): Observable<any> {
    const url = this.url_array(['pdf']);
    const projectIds = id.join(',');
    const options: Object = {
      params: new HttpParams()
        .set('ids', projectIds)
        .set('type', type),
      responseType: 'blob',
      observe: 'response'
    };
    return this.http.get<any>(url, options).pipe(
      tap({
        next: (data: HttpResponse<any>) => {
          const blob = new Blob([data.body], { type: 'application/pdf' })
          saveAs(blob, this.helper.getDownloadedFileName(data.headers.get('Content-Disposition')));
        }
      })
    );
    // window.location.href = isDevMode() ? `${environment.host}${url}?ids=${projectIds}&type=${type}` : `${url}?ids=${projectIds}&type=${type}`;
  }

  exportXlsx(id: number[]): Observable<any> {
    const url = this.url_array(['xlsx']);
    const projectIds = id.join(',');
    const options: Object = {
      params: new HttpParams()
        .set('ids', projectIds),
      responseType: 'blob',
      observe: 'response'
    };
    return this.http.get<any>(url, options).pipe(
      tap({
        next: (data: HttpResponse<any>) => {
          const blob = new Blob([data.body], { type: 'application/pdf' })
          console.log(data.headers.get('Content-Disposition'), blob);
          saveAs(blob, this.helper.getDownloadedFileName(data.headers.get('Content-Disposition')));
        }
      })
    );
    // window.location.href = isDevMode() ? `${environment.host}${url}?ids=${projectIds}` : `${url}?ids=${projectIds}`;
  }
}
