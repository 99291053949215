import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HelpersService } from '../helpers.service';
import { MarriedStatus } from './married-status';

@Injectable({
  providedIn: 'root'
})
export class MarriedStatusService extends BaseApiService<MarriedStatus> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'employee-married-status';
  }
}
