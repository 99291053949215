import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { ConfirmDialogComponent } from 'app/layout/components/confirm-dialog/confirm-dialog.component';
import { locale as en } from './i18n/en';
import { locale as id } from './i18n/id';

@Component({
  selector: 'app-toolbar-button',
  templateUrl: './toolbar-button.component.html',
  styleUrls: ['./toolbar-button.component.scss']
})
export class ToolbarButtonComponent implements OnInit {

  constructor(private _fuseTranslationLoaderService: FuseTranslationLoaderService, private dialog: MatDialog) {
    this._fuseTranslationLoaderService.loadTranslations(en, id);
  }
  @Input() selected = 0;
  @Input() disableDelete = false;
  @Input() maintenanceReturn = false;
  @Output() filter = new EventEmitter();
  @Output() delAll = new EventEmitter();
  @Output() delSelected = new EventEmitter();
  @Output() retSelected = new EventEmitter();
  ngOnInit(): void {
  }
  removeAll() {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe((isTrue) => {
      if (isTrue) {
        this.delAll.emit(true);
      }
    });
  }
  removeSelected() {
    this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe((isTrue) => {
      if (isTrue) {
        this.delSelected.emit(true);
      }
    });
  }
  openFilter() {
    this.filter.emit(true);
  }
  returnSelected() {
    this.retSelected.emit(true);
    // this.dialog.open(ConfirmDialogComponent).afterClosed().subscribe((isTrue) => {
    //   if (isTrue) {
    //   }
    // });
  }
}
