import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-cuan-label',
  templateUrl: './cuan-label.component.html',
  styleUrls: ['./cuan-label.component.scss']
})
export class CuanLabelComponent implements OnInit {

  @Input() data: any;
  @Input() isParsed = true;
  @Input() noSymbol: boolean = false;
  @Input() price: any;

  constructor() { }

  ngOnInit(): void {
  }
  sign(n) {
    return Math.sign(n);
  }
  isOverprice(data, price){
    return parseInt(data) > parseInt(price);
  }
}
