import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from '../base-api.service';
import { HelpersService } from '../helpers.service';
import { EmployeeStatus } from './employee-status';

@Injectable({
  providedIn: 'root'
})
export class EmployeeStatusService extends BaseApiService<EmployeeStatus> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'employee-status';
  }
}
