import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { LogsActivity } from './logs-activity';

@Injectable({
  providedIn: 'root'
})
export class LogsActivityService extends BaseApiService<LogsActivity> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'activity-logs';
  }
}
