import { Component, Input, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-date-label',
  templateUrl: './date-label.component.html',
  styleUrls: ['./date-label.component.scss']
})
export class DateLabelComponent implements OnInit {

  @Input() colorName = 'default';
  @Input() data: any;
  @Input() isParsed = true;
  color: string;

  constructor(public trans: TranslateService) { }

  ngOnInit(): void {
    // this.setColor();
  }

  // setColor() {
  //   if (this.colorName === 'warn') {
  //     this.color = 'warn';
  //   } else if (this.colorName === 'blue') {
  //     this.color = 'blue';
  //   } else if (this.colorName === 'lime') {
  //     this.color = 'lime';
  //   } else if (this.colorName === 'indigo') {
  //     this.color = 'indigo';
  //   } else if (this.colorName === 'pink') {
  //     this.color = 'pink';
  //   } else {
  //     this.color = 'default';
  //   }
  // }

}
