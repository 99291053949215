import { Component, OnInit } from '@angular/core';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { TranslateService } from '@ngx-translate/core';
import { locale as en } from './i18n/en';
import { locale as id } from './i18n/id';

@Component({
  selector: 'app-nodata-label',
  templateUrl: './nodata-label.component.html',
  styleUrls: ['./nodata-label.component.scss']
})
export class NodataLabelComponent implements OnInit {

  constructor(public fuseTranslationLoader: FuseTranslationLoaderService, public trans: TranslateService) {
    this.fuseTranslationLoader.loadTranslations(en, id);
  }

  ngOnInit(): void {
  }

}
