import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseAPIObject, BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { SiteSettings } from './site-settings';

@Injectable({
  providedIn: 'root'
})
export class SiteSettingsService extends BaseApiService<SiteSettings> {

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'sites';
  }

  updateById(id: string, data: any): Observable<BaseAPIObject<SiteSettings>> {
    const url = this.url_id(id);
    return this.http.post<BaseAPIObject<SiteSettings>>(url, data);
  }
}
