import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { BaseAPIObject, BaseApiService } from './base-api.service';
import { HelpersService } from './helpers.service';
import { NavCounter } from './nav-counter';

@Injectable({
  providedIn: 'root'
})
export class NavCounterService extends BaseApiService<NavCounter>{

  projectRefresh$ = new BehaviorSubject(null);
  regionsRefresh$ = new BehaviorSubject(null);
  invoicesRefresh$ = new BehaviorSubject(null);
  userRefresh$ = new BehaviorSubject(null);
  approvalRefresh$ = new BehaviorSubject(null);
  auctionRefresh$ = new BehaviorSubject(null);
  jobRefresh$ = new BehaviorSubject(null);

  projectCount$ = new BehaviorSubject({
    all: 0,
    planned: 0,
    onprogress: 0,
    done: 0,
    closed: 0,
    canceled: 0,
    onhold: 0
  })

  constructor(
    public http: HttpClient,
    public helper: HelpersService
  ) {
    super(http, helper);
    this.endpoint = 'counters';
  }
  projects(status = 'all'): Observable<BaseAPIObject<NavCounter>>{
    const url = this.url_array(['projects']);
    const params = new HttpParams().set('status', status);
    return this.http.get<BaseAPIObject<NavCounter>>(url, {params});
  }
  jobs(): Observable<BaseAPIObject<NavCounter>> {
    const url = this.url_array(['jobs']);
    return this.http.get<BaseAPIObject<NavCounter>>(url);
  }
  regions(status = 'provinces'): Observable<BaseAPIObject<NavCounter>>{
    const url = this.url_array(['regions']);
    const params = new HttpParams().set('scope', status);
    return this.http.get<BaseAPIObject<NavCounter>>(url, {params});
  }
  invoices(status = 'all'): Observable<BaseAPIObject<NavCounter>>{
    const url = this.url_array(['invoices']);
    const params = new HttpParams().set('param', status);
    return this.http.get<BaseAPIObject<NavCounter>>(url, {params});
  }
  users(status = 'all'): Observable<BaseAPIObject<NavCounter>>{
    const url = this.url_array(['users']);
    const params = new HttpParams().set('role', status);
    return this.http.get<BaseAPIObject<NavCounter>>(url, {params});
  }
  approval(status = 'all'): Observable<BaseAPIObject<NavCounter>>{
    const url = this.url_array(['approval']);
    const params = new HttpParams().set('type', status);
    return this.http.get<BaseAPIObject<NavCounter>>(url, {params});
  }
  auctions(status = 'all'): Observable<BaseAPIObject<NavCounter>> {
    const url = this.url_array(['auctions']);
    const params = new HttpParams().set('type', status);
    return this.http.get<BaseAPIObject<NavCounter>>(url, {params});
  }
  countAuctions(): Observable<any> {
    const all = this.auctions('').pipe(map(res => res.data.count));
    const win = this.auctions('win').pipe(map(res => res.data.count));
    const lose = this.auctions('lose').pipe(map(res => res.data.count));
    const onprogress = this.auctions('on progress').pipe(map(res => res.data.count));
    const notqualified = this.auctions('pq not qualified').pipe(map(res => res.data.count));
    const cancelled = this.auctions('auction cancelled').pipe(map(res => res.data.count));
    const notjoined = this.auctions('not joined').pipe(map(res => res.data.count));
    return forkJoin({
      all,
      win,
      lose,
      onprogress,
      notqualified,
      cancelled,
      notjoined
    });
  }
  countProjecs(): Observable<any> {
    const all = this.projects('').pipe(
      map(res => res.data.count),
      tap({
        next: res => {
          const countValue = this.projectCount$.value;
          countValue.all = res;
          this.projectCount$.next(countValue);
        }
      })
    );
    const planned = this.projects('rencana').pipe(
      map(res => res.data.count),
      tap({
        next: res => {
          const countValue = this.projectCount$.value;
          countValue.planned = res;
          this.projectCount$.next(countValue);
        }
      })
    );
    const onprogress = this.projects('berlangsung').pipe(
      map(res => res.data.count),
      tap({
        next: res => {
          const countValue = this.projectCount$.value;
          countValue.onprogress = res;
          this.projectCount$.next(countValue);
        }
      })
    );
    const done = this.projects('selesai').pipe(
      map(res => res.data.count),
      tap({
        next: res => {
          const countValue = this.projectCount$.value;
          countValue.done = res;
          this.projectCount$.next(countValue);
        }
      })
    );
    const closed = this.projects('lunas').pipe(
      map(res => res.data.count),
      tap({
        next: res => {
          const countValue = this.projectCount$.value;
          countValue.closed = res;
          this.projectCount$.next(countValue);
        }
      })
    );
    const canceled = this.projects('batal').pipe(
      map(res => res.data.count),
      tap({
        next: res => {
          const countValue = this.projectCount$.value;
          countValue.canceled = res;
          this.projectCount$.next(countValue);
        }
      })
    );
    const onhold = this.projects('ditunda').pipe(
      map(res => res.data.count),
      tap({
        next: res => {
          const countValue = this.projectCount$.value;
          countValue.onhold = res;
          this.projectCount$.next(countValue);
        }
      })
    );
    return forkJoin({
      all,
      planned,
      onprogress,
      done,
      closed,
      canceled,
      onhold
    });
  }
  countRegions(): Observable<any>{
    const province = this.regions('provinces').pipe(map(res => res.data.count));
    const city = this.regions('cities').pipe(map(res => res.data.count));
    const district = this.regions('districts').pipe(map(res => res.data.count));
    const subdistrict = this.regions('sub-districts').pipe(map(res => res.data.count));
    return forkJoin({ province, city, district, subdistrict});
  }
  countUsers(): Observable<any>{
    const all = this.users('').pipe(map(res => res.data.count));
    const project = this.users('project').pipe(map(res => res.data.count));
    const monetary = this.users('monetary').pipe(map(res => res.data.count));
    const logistic = this.users('logistic').pipe(map(res => res.data.count));
    return forkJoin({ all, project, monetary, logistic});
  }
  countApproval(): Observable<any>{
    const add = this.approval('add').pipe(map(res => res.data.count));
    const rental = this.approval('rent').pipe(map(res => res.data.count));
    const rentreturn = this.approval('rent-return').pipe(map(res => res.data.count));
    const discontinue = this.approval('discontinue').pipe(map(res => res.data.count));
    const maintenance = this.approval('maintenance').pipe(map(res => res.data.count));
    const maintenancereturn = this.approval('maintenance-return').pipe(map(res => res.data.count));
    return forkJoin({ add, rental, rentreturn, discontinue, maintenance, maintenancereturn });
  }
}
